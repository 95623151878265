import { ACTION_TYPES } from "../actions";

const initialState = {
  cartSettings: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.CART_SETTINGS_LOADED:
      return {
        ...state,
        cartSettings: action.payload,
      };
    default:
      return state;
  }
}
