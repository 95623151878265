import React, { useEffect, useState } from "react";
import Loader from "../../../../../components/common/loader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  FormGroup,
} from "reactstrap";
import AddressForm from "../../address-form";
import useAuthStatus from "../../../../../common/auth-status";
import { useTranslation } from "react-i18next";
import AddressItem from "./address-item";

const AddressList = (props) => {
  const { t } = useTranslation();
  const [authLoading, chapelDbUserLoaded] = useAuthStatus();
  const [addLoading, setAddLoading] = useState(true);
  const [addDefSetting, setAddDefSetting] = useState(false);
  const [addDefId, setAddDefId] = useState(null);
  const [error, setError] = useState("");
  const [checkedAddresses, setCheckedAddresses] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);

  const loadAddresses = async () => {
    props.getUserAddresses(props.token, props.dbUser.userId);
    setCheckedAddresses(true);
  };

  const setDefAddress = async (address) => {
    const isForeign = address.countryId != "US";
    try {
      setAddDefSetting(true);
      props.setDefaultUserAddress(
        props.token,
        props.dbUser.userId,
        address.id,
        isForeign
      );
      setAddDefId(address.id);
      setAddDefSetting(false);
    } catch (e) {
      setError(e.message);
    }
  };

  const setForm = (prop, value) => {
    let form = props.checkoutForm;
    form[prop] = value;
    props.changeCheckoutForm(form);
  };
  const selectAddress = (a) => {
    props.updateDbUser(a);
    setForm("addressId", a.id);
  };
  useEffect(() => {
    if (!authLoading && chapelDbUserLoaded) {
      setAddDefId(props.dbUser.defaultAddress);
      setForm("addressId", props.dbUser.defaultAddress);
      setForm("userId", props.dbUser.userId);

      //situation: loaded new address
      if (showAddressForm) {
        setShowAddressForm(false);
      }

      //situation: no addresses yet
      if (!props.addresses.length && !checkedAddresses) {
        setAddLoading(true);
        loadAddresses();
      }

      if (props.addresses.length || checkedAddresses) {
        setAddLoading(false);
      }

      if (!props.addresses.length && checkedAddresses) {
        setShowAddressForm(true);
      }

      if (props.addresses.length === 1) {
        if (props.dbUser.defaultAddress !== props.addresses[0].id) {
          setDefAddress(props.addresses[0]);
        }
        setForm("addressId", props.addresses[0].id);
      }
    }
    if (!chapelDbUserLoaded) {
      setAddLoading(false);
    }
  }, [authLoading, chapelDbUserLoaded, props.addresses]);

  return authLoading ? (
    <Loader />
  ) : (
    <>
      {props.addresses.length > 0 && (
        <Card className="mb-4" style={{ marginBottom: "40px" }}>
          <CardHeader>{t("address")}</CardHeader>
          <CardBody>
            {props.flavor != "donate" && (
              <CardTitle>{t("address-select-manage")}</CardTitle>
            )}
            {error !== "" && (
              <div>
                <p>{t("cart-posted-error")}</p>
                {error}
              </div>
            )}
            {error === "" && (
              <>
                {addLoading && <Loader />}
                {!addLoading && (
                  <FormGroup tag="fieldset">
                    {props.addresses.map((a, idx) => {
                      return (
                        <AddressItem
                          a={a}
                          key={idx}
                          deleteAddress={props.deleteAddress}
                          addDefId={addDefId}
                          setDefAddress={setDefAddress}
                          addDefSetting={addDefSetting}
                          isCartContext={
                            props.flavor === "cart" || props.flavor === "donate"
                          }
                          checkoutForm={props.checkoutForm}
                          dbUser={props.dbUser}
                          selectAddress={selectAddress}
                          settings={props.settings}
                        />
                      );
                    })}
                  </FormGroup>
                )}
              </>
            )}
          </CardBody>
          <CardFooter>
            {props.flavor != "donate" && (
              <div
                className="mt-3"
                dangerouslySetInnerHTML={{
                  __html: t("address-update-disclaimer"),
                }}
              ></div>
            )}
          </CardFooter>
        </Card>
      )}
      <Card>
        {!showAddressForm && (
          <Button color="primary" onClick={() => setShowAddressForm(true)}>
            {t("address-new")}
          </Button>
        )}
        {showAddressForm && (
          <AddressForm setShowAddressForm={setShowAddressForm} />
        )}
      </Card>
      <br />
    </>
  );
};

export default AddressList;
