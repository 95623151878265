import { connect } from "react-redux";
import App from "./components/";
import { getCartSettings } from "../../actions";

const mapStateToProps = ({ search, modal, messenger }) => ({
  search,
  modal,
  messenger,
});

const mapDispatchToProps = (dispatch) => ({
  getCartSettings: () => dispatch(getCartSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
