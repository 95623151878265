import { connect } from "react-redux";
import CheckoutFormWithScript from "./components";
import {
  changeCheckoutForm,
  saveCartOrder,
  saveDonateOrder,
  sumValueInCart,
  saveRegularGiving,
} from "../../../actions";

const mapDispatchToProps = (dispatch) => ({
  changeCheckoutForm: (contact) => dispatch(changeCheckoutForm(contact)),
  saveCart: () => dispatch(saveCartOrder()),
  saveDonate: () => dispatch(saveDonateOrder()),
  saveRegularGiving: () => dispatch(saveRegularGiving()),
});

const mapStateToProps = (state) => ({
  areBooksAvailable:
    state.cart.selectedBooks.filter((r) => r.counter > 0).length > 0,
  form: state.cart.checkoutForm,
  cartBooks: state.cart.selectedBooks,
  valInCart: sumValueInCart(state.cart),
  addresses: state.user.addresses,
  dbUser: state.user.dbUser,
  cartSettings: state.settings.cartSettings,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutFormWithScript);
