import React from "react";
import { useTranslation } from "react-i18next";

const EmailSubscribeForm = () => {
  const { t, i18n } = useTranslation();

  const appLang = i18n.language.toUpperCase().substring(0, 2);

  const iframeSrc =
    appLang == "ES"
      ? "https://sibforms.com/serve/MUIFALZw4Bu0uCCR9kYt3dgcTJajRSG-wdJ_JPMFXvEkeRM6A3bAFKTmftNnjCiM-8CbEfz1ebFCw-rRhN4hgLUlqyrllzPV0B4o-thHqfLHPayAF5pYGnvMjAscEhhOe1-HENA_kFCe9jWermmCE4HWEwuQKhpBWwDp1qVNSTRJKJL-x_xkPkq-SInVVxOXAInqvmBnTE_Xbk9X"
      : "https://sibforms.com/serve/MUIFANdQVPUCsiurkHP5NjExV33aUEdUknt1AQ8mD1KH5Gr61yo-49_zWzVAlS20HKoibQMDmYEQlQGjhN06WGgMIm2vwf3nziPQL720XFnbV5sR9ontjnjAlUymngwxV3BWUzuUPCr7BVyAoiyJLmEcKP1Uyt_4RjaLB032ATz6Y9FhVjIk-AdOkeRvbfVRk7XUsSpUUkZMOFxY";

  return (
    <div>
      <h2 className="mt-3 mb-3">{t("subscription-p7")}</h2>
      <p>
        {t("subscription-p8")}{" "}
        <a
          href="/about/privacy-policy"
          target="_blank"
          className="btn btn-light btn btn-secondary"
          style={{ textDecoration: "underline" }}
        >
          {t("privacy-policy")}
        </a>
      </p>
      <div
        className="input-group-text subscriptions-items"
        style={{ whiteSpace: "pre-wrap" }}
      >
        <iframe
          width="540"
          height="1000"
          src={iframeSrc}
          frameborder="0"
          allowfullscreen
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "100%",
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default EmailSubscribeForm;
