import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { findUserByExternalId, setToken } from "../actions";

function useAuthStatus() {
  const { user, isLoading, getAccessTokenSilently, isAuthenticated } =
    useAuth0();
  const [authLoading, setAuthLoading] = useState(true);
  const [triedDbUser, setTriedDbUser] = useState(false);
  const [chapelDbUserLoaded, setChapelDbUserLoaded] = useState(false);
  const dbUser = useSelector((state) => state.user.dbUser);
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const loadToken = async () => {
    try {
      const t = await getAccessTokenSilently();
      dispatch(setToken(t));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (!isLoading && typeof user !== "undefined" && user.sub.length > 0) {
        if (token.length === 0) {
          loadToken();
        } else {
          if (
            (typeof dbUser === "undefined" ||
              typeof dbUser.userId === "undefined") &&
            !triedDbUser
          ) {
            dispatch(findUserByExternalId(user.sub, token));
            setTriedDbUser(true);
          } else if (
            typeof dbUser.userId !== "undefined" &&
            dbUser.userId > 0
          ) {
            setChapelDbUserLoaded(true);
            setAuthLoading(false);
          } else {
            setAuthLoading(false);
          }
        }
      }
    } else {
      if (!isLoading) {
        setAuthLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [isAuthenticated, isLoading, user, token, dbUser]);

  useEffect(() => {
    if (dbUser.userId > 0) {
      setChapelDbUserLoaded(true);
      setTriedDbUser(true);
    } else {
      setChapelDbUserLoaded(false);
    }
  }, [dbUser]);

  useEffect(() => {
    if (triedDbUser && typeof dbUser.userId === "undefined") {
      setAuthLoading(false);
      setChapelDbUserLoaded(false);
      //navigate("/cart");
    }
  }, [triedDbUser, dbUser]);

  return [authLoading, chapelDbUserLoaded];
}

export default useAuthStatus;
