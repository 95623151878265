import React, { useState, useEffect } from "react";
import AddressList from "../../../account/components/address-list";
import UserForm from "../../../account/components/user-form";
import ValidatedInput from "../../../../components/common/input/validated-input";
import { INPUT_TYPES } from "../../../../common/constants";
import { Alert, Card, CardBody } from "reactstrap";
import withExternalJsScript from "../../../common/with-external-js-script";
import { useTranslation } from "react-i18next";
import FreePaySelect from "./free-pay-select";
import config from "../../../../cornerstone_config.json";
import CornerstonePayForm from "../../../common/cornerstone-pay-form";
import DropDown from "../../dropdown";
import { Link } from "react-router-dom";
import ipify from "ipify2";
import UserTaxId from "../../../account/components/user-tax-id";

const CheckOutForm = (props) => {
  const { t } = useTranslation();

  const [sending, setSending] = useState(false);
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(true);
  const [validDonation, setValidDonation] = useState(true);
  const [cartItemsValue, setCartItemsValue] = useState(0);
  const [selectedInterval, setSelectedInterval] = useState({
    name: "frequency-once",
    id: 1,
  });
  const [shippingValue, setShippingValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [addressPasses, setAddressPasses] = useState(false);
  const [taxIdPasses, setTaxIdPasses] = useState(false);
  const [countryOrderingPasses, setCountryOrderingPasses] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [paypalWindowOpen, setPaypalWindowOpen] = useState(false);
  const [error, setError] = useState(null);

  const freeLimitToUse = props.dbUser.freeLimit;

  const intervals = [
    { name: "frequency-once", id: 1 },
    { name: "frequency-monthly", id: 2 },
  ];

  const onIntervalClick = (e) => {
    let targetInterval = intervals.filter((i) => i.id == e.target.value)[0];
    setSelectedInterval(targetInterval);
    setForm("interval", targetInterval.name);
  };
  function validateForm() {
    if (
      props.flavor === "donate" &&
      (props.form.donation === undefined || props.form.donation < 0.1)
    )
      return false;
    if (!validDonation) return validDonation;
    return true;
  }
  useEffect(() => {
    if (props.flavor === "donate") {
      setPaypalWindowOpen(true);
    }
  }, [props.flavor]);

  useEffect(() => {
    const donVal =
      props.form.donation !== undefined && props.form.donation > 0
        ? props.form.donation
        : 0;
    const shippingRate =
      selectedAddress?.countryId == "US"
        ? props.cartSettings.collectShippingPercentUS
        : props.cartSettings.collectShippingPercentForeign;
    const _freeLimit = props.form.type == "cover" ? 0 : freeLimitToUse;
    const itemsPayVal = Math.max(cartItemsValue - _freeLimit, 0);
    const shippingPayVal = Math.max(
      parseFloat(shippingRate) * cartItemsValue,
      0
    );
    const payVal = (itemsPayVal + shippingPayVal + parseFloat(donVal)).toFixed(
      2
    );
    setShippingValue(shippingPayVal);

    setTotalValue(payVal);
  }, [
    props.form.donation,
    cartItemsValue,
    freeLimitToUse,
    selectedAddress,
    props.form.type,
  ]);

  function onCheckoutClick(e) {
    e.preventDefault();
    if (validateForm()) {
      if (totalValue > 0.01) {
        setPaypalWindowOpen(true);
      } else {
        setSending(true);
        props.saveCart();
      }
    }
  }

  function finishSubmit(response) {
    setForm("paymentId", response.token);
  }

  function onPolicyChecked(accepted) {
    setIsPolicyAccepted(accepted);
  }

  const setForm = (prop, value) => {
    let form = Object.assign({}, props.form);
    form[prop] = value;
    props.changeCheckoutForm(form);
  };

  useEffect(() => {
    if (props.form.caten === undefined) setForm("caten", false);
    if (props.form.cates === undefined) setForm("cates", false);
    if (!props.form.ipAddress) {
      ipify
        .ipv4()
        .then((ipv4) => setForm("ipAddress", ipv4))
        .catch((err) => console.log(err));
    }
  }, [props.form]);

  useEffect(() => {
    let localSelectedAddress =
      typeof props.addresses.find((a) => a.id === props.form.addressId) !==
        "undefined" &&
      props.addresses.find((a) => a.id === props.form.addressId).id > 0
        ? props.addresses.find((a) => a.id === props.form.addressId)
        : {};
    let localAddressPasses =
      Object.keys(localSelectedAddress).length > 0 ? true : false;
    let localTaxIdFails = false;
    let localCountryOrderingPasses = false;
    let localCountryAppRulePasses = false;
    if (localAddressPasses) {
      localTaxIdFails =
        localSelectedAddress?.countryRequiresTaxId &&
        props.dbUser?.taxId?.length < 6;
      localCountryAppRulePasses =
        (localSelectedAddress.countryId != "US" &&
          props.cartSettings.cartEnabledForeign == "1") ||
        (localSelectedAddress.countryId == "US" &&
          props.cartSettings.cartEnabledUS == "1");

      localCountryOrderingPasses =
        localSelectedAddress?.countryOrderingEnabled &&
        localCountryAppRulePasses;
    }

    setTaxIdPasses(!localTaxIdFails);
    setAddressPasses(localAddressPasses);
    setCountryOrderingPasses(localCountryOrderingPasses);
    setSelectedAddress(localSelectedAddress);
  }, [props.addresses, props.form.addressId]);

  useEffect(() => {
    setCartItemsValue(props.valInCart);
  }, [props.valInCart]);

  useEffect(() => {
    if (props.valInCart > freeLimitToUse && props.form["type"] === "free") {
      setForm("type", "pay");
    }
  }, [props.valInCart, freeLimitToUse]);

  useEffect(() => {
    if (props.form.paymentId && props.form.paymentId.length > 1) {
      if (props.flavor === "cart") props.saveCart();
      if (props.flavor === "donate") {
        if (selectedInterval.name == "frequency-monthly") {
          props.saveRegularGiving();
        } else {
          props.saveDonate();
        }
      }
    } else if (props.form.paymentId == "") {
      setSending(false);
      setPaypalWindowOpen(false);
    }
  }, [props.form.paymentId]);

  return (
    <fieldset>
      <div className="form-group">
        <div>
          <div className="text-relevant">
            <h2>{props.flavor === "donate" ? t("donate") : t("checkout")}</h2>
          </div>
          <UserForm />
          <UserTaxId />
          {props.dbUser.userId && <AddressList flavor={props.flavor} />}
          {props.flavor === "cart" &&
            props.areBooksAvailable &&
            props.dbUser.userId && (
              <Card>
                <FreePaySelect
                  form={props.form}
                  dbUser={props.dbUser}
                  valInCart={props.valInCart}
                  setForm={setForm}
                />
                <CardBody>
                  <h5>{t("cart-value")}</h5>
                  <div
                    className="col-12 d-flex flex-wrap d-row input-group-text align-content-center"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <strong>
                      ${Math.min(cartItemsValue, freeLimitToUse).toFixed(2)}{" "}
                      {t("free")} | $
                      {Math.max(cartItemsValue - freeLimitToUse, 0).toFixed(2)}{" "}
                      {t("pay")} - {t("cart-over-free-limit")}
                    </strong>
                  </div>
                  <br />
                </CardBody>
                {shippingValue > 0.01 && (
                  <CardBody>
                    <h5>{t("shipping-value")}</h5>
                    <Alert style={{ whiteSpace: "pre-wrap" }} color="warning">
                      <div>
                        <strong>${shippingValue.toFixed(2)}</strong>
                      </div>
                      <div>{t("shipping-value-explanation")}</div>
                    </Alert>
                    <br />
                  </CardBody>
                )}
              </Card>
            )}
          {props.dbUser.userId && (
            <Card>
              <CardBody>
                {props.flavor === "cart" && <h5>{t("cart-donation")}</h5>}
                <div
                  className="col-12 d-flex flex-wrap d-row input-group-text align-items-center"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  ${" "}
                  <ValidatedInput
                    type="string"
                    className="form-control col"
                    errorMessage={t("input-error-message")}
                    enabled={true}
                    required={false}
                    inputType={INPUT_TYPES.CURRENCY}
                    isValid={validDonation}
                    onBlur={(_, valid) => setValidDonation(valid)}
                    onChange={(value) => setForm("donation", value)}
                    placeholder={t("donate")}
                    value={props.form.donation}
                  />{" "}
                  <span className="ml-1">USD</span>
                </div>
                <p>{t("cart-donation-explanation")}</p>
                {props.flavor === "donate" &&
                  props.dbUser.regularGivings.length < 1 && (
                    <div
                      className="col-12 d-flex flex-wrap d-row input-group-text align-items-center"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      <DropDown
                        className="col-md-4"
                        enabled={true}
                        items={intervals}
                        label={
                          selectedInterval ? selectedInterval.name : t("select")
                        }
                        text="name"
                        value="id"
                        onClick={onIntervalClick}
                        translateItems
                      />
                      {selectedInterval && selectedInterval.id == 2 && (
                        <span>{t("frequency-monthly-details")}</span>
                      )}
                    </div>
                  )}
                {props.flavor === "donate" &&
                  props.dbUser.regularGivings.length > 0 && (
                    <div
                      className="alert alert-secondary small"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {t("regular-giving-instructions")}
                      <Link to={"/account"}>{t("account")}</Link>
                    </div>
                  )}
                <br />
                <h5>{t("privacy-policy")}</h5>
                <label className="mb-3">
                  <input
                    type="checkbox"
                    onChange={(e) => onPolicyChecked(e.target.checked)}
                    checked={isPolicyAccepted}
                  />
                  {t("privacy-policy-agreedment")}
                  <a
                    href="/about/privacy-policy"
                    target="_blank"
                    className="btn btn-light btn btn-secondary"
                    style={{ textDecoration: "underline" }}
                  >
                    {t("privacy-policy")}
                  </a>
                </label>

                <h5>{t("cart-total")}</h5>
                <Alert color="warning">
                  <strong>${parseFloat(totalValue).toFixed(2)}</strong>
                </Alert>

                {paypalWindowOpen && (
                  <div className="cornerstone mb-4">
                    <CornerstonePayForm
                      parentActionHandle={finishSubmit}
                      parentValidateForm={validateForm}
                      amount={totalValue}
                      selectedInterval={selectedInterval}
                    />
                  </div>
                )}
                {error && <Alert color="danger">{error}</Alert>}
                {!paypalWindowOpen && (
                  <button
                    className={"btn col-12 btn-info"}
                    disabled={
                      (props.flavor === "cart" && !props.areBooksAvailable) ||
                      !isPolicyAccepted ||
                      !addressPasses ||
                      !taxIdPasses ||
                      !countryOrderingPasses ||
                      sending
                    }
                    onClick={onCheckoutClick}
                  >
                    <span>
                      {t("checkout")}
                      {sending && (
                        <i className="fa fas fa-spinner fa-spin ml-3"></i>
                      )}
                    </span>
                  </button>
                )}
                {!addressPasses && (
                  <p style={{ color: "red" }}>{t("cart-select-address")}</p>
                )}
                {!taxIdPasses && selectedAddress.countryName && (
                  <p style={{ color: "red" }}>
                    {t("cart-tax-id-required", {
                      countryName: selectedAddress?.countryName,
                    })}
                  </p>
                )}
                {!countryOrderingPasses && selectedAddress.countryName && (
                  <p style={{ color: "red" }}>
                    {t("cart-country-ordering-disabled", {
                      countryName: selectedAddress?.countryName,
                    })}
                  </p>
                )}
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </fieldset>
  );
};

const CheckoutFormWithScript = withExternalJsScript(
  "https://cps.transactiongateway.com/token/Collect.js",
  CheckOutForm,
  {
    attributes: {
      "data-tokenization-key": config.security_key,
      "data-variant": "inline",
    },
  }
);

export default CheckoutFormWithScript;
